.container{
    text-align: center;
    margin:4rem 0;
    display:flex;
    flex-direction: column;
}

.title{
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}

.menuLink{
    margin: 2rem 40rem;
    width:auto;
    text-decoration: none;
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    color: #23518D;
    background-color: #ffffff;
    cursor:pointer;
}

.menuLink2{
    margin:0 40rem;
    width:auto;
    text-decoration: none;
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    color: #23518D;
    background-color: #ffffff;
    cursor:pointer;
}

.menuLink:hover{
    background-color: #b3d3fd;
}

/* .menuText:hover{
    color:#aaccf8;
    
} */

.menuText{
    color: #23518D;
    font-size: 2rem;
    font-weight: 600;
}


@media(max-width:115em){
    .menuLink{
        margin:2rem 25rem;
    }
    .menuLink2{
        margin:0 25rem;
    }
}


@media(max-width:100em){
    .container{
        margin:3rem 0;
    }
    .title{
        font-size: 2.5rem;
    }
    .menuLink{
        margin:2rem 20rem;
    }
    .menuLink2{
        margin:0 20rem;
    }
}


@media(max-width:80em){
    .container{
        margin:3rem 0;
    }
    .menuLink{
        margin:2rem 10rem;
    }
    .menuLink2{
        margin:0 8rem;
    }
    .menuText{
        font-size: 1.7rem;
    }
    .title{
        font-size: 2.2rem;
    }
}

@media(max-width:60em){
  
    .title{
        font-size: 1.8rem;
    }

    .menuLink{
        margin:2rem 5rem;
    }
    .menuLink2{
        margin: 0 5rem;
    }

    .menuText{
        font-size: 1.5rem;
    }
}

@media(max-width:40em){
  
    .title{
        font-size: 1.8rem;
    }

    .menuLink{
        margin:2rem 3rem;
    }
    .menuLink2{
        margin: 0 3rem;
    }


    .menuText{
        font-size: 1.3rem;
    }
}

@media(max-width:30em){
  
    .title{
        font-size: 1.8rem;
    }

    .menuLink{
        margin:2rem .5rem;
    }
    .menuLink2{
        margin:0 .5rem;
    }

    .menuText{
        font-size: 1rem;
    }
}
