.itemContainer{
    margin:2rem 20rem;
    padding:2rem 3rem;
    border-color: #ffffff;
    border-radius: 2rem;
    border-width: .5rem;
    border-style: solid;
    display: flex;
    flex-direction: column;
    width:80%;
}

.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}

.line1{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    justify-content: space-between;
}
.icons{
    display:flex;
    flex-direction: row;
    column-gap:1rem;
    align-items: center;
    justify-content: flex-start;

}
.requestInfo{
    display:flex;
    flex-direction: row;
    column-gap:2rem;
    align-items: center;
    justify-content: flex-start;

}

.line2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 2rem;
    
}
.line3{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 2rem;
    
}
.line4{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.itemText{
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
}

.editBtn,
.editBtn:active,
.editBtn:link,
.editBtn:visited{
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    margin-left: 1rem;
    cursor:pointer;
}
.editBtn:hover{
    background-color: #7295c4;
}

.completeBtn,
.completeBtn:active,
.completeBtn:link,
.completeBtn:visited{
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    margin-left: 1rem;
    cursor:pointer;
}
.completeBtn:hover{
    background-color: #7295c4;
}

.commentContainer{
    display:flex;
    width:100%;
    flex-direction: row;
    column-gap:2rem;
    align-items: center;
    justify-content: flex-start;
}

.labelText{
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
}

.comment{
    width:100%;
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#111111;
}

@media(max-width:100em){
    .title{
        font-size:2rem;
    }
    .itemText{
        font-size: 1.8rem;
    }
    .labelText{
        font-size: 1.8rem;
    }
    .comment{
        font-size: 1.8rem;
    }
    .errorMsg{
        font-size: 1.8rem;
    }
    .itemContainer{
        margin:2rem 4rem;
        width:80%;
    }
 
}

@media(max-width:65em){

    .itemContainer{
        width:90%;
        margin:.5rem 1rem 1rem 1rem;
        padding:.5rem 1rem .5rem 1rem;
        border-width: .1rem;
    }


    .title{
        font-size:1.5rem;
    }
    .itemText{
        font-size: 1rem;
    }
    .labelText{
        font-size: 1rem;
    }
    .comment{
        font-size: 1rem;
    }
    .errorMsg{
        font-size: 1.5rem;
    }
    .commentContainer{
        column-gap: .3rem;
    }
    .line1{
        column-gap:.3rem;
    }
    .line3{
        column-gap:.5rem;
    }

    .completeBtn,
    .completeBtn:active,
    .completeBtn:link,
    .completeBtn:visited{
        padding:.2rem;
        border-width: .1rem;
        height: 1.5rem;
        width:1.5rem;
        margin-left: 1rem;
    }

    .editBtn,
    .editBtn:active,
    .editBtn:link,
    .editBtn:visited{
        padding:.2rem;
        border-width: .1rem;
        height: 1.5rem;
        width:1.5rem;
        margin-left: 1rem;
    }


}

@media(max-width:40em){
    .errorMsg{
        font-size: 1.2rem;
    }

    .itemContainer{
        width:90%;
        margin:.5rem .5rem 1rem .5rem;
        padding:.5rem 1rem .5rem 1rem;
        border-width: .1rem;
    }
}
