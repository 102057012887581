.loadingImg {
    width:20rem;
    margin:auto;
    display: block;
}


@media(max-width:100em){
    .loadingImg{
        width:8rem;
    }
}