.foodListContainer{
    margin:2rem 20rem;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}
   
.row{ 
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:1rem;
}

.foodItem{
  display:flex;
  align-items: center;
  justify-content: space-between;
  gap:1rem;
}

.iconNever{
    width:3rem;
    height:3rem;
    text-align: right;
}
.iconInfrequent{
    width:3rem;
    height:3rem;
    text-align: right;
}

.iconRarely{
    width:3rem;
    height:3rem;
    text-align: right;
}
.iconOk{
    width:3rem;
    height:3rem;
    text-align: right;
}

.foodName{
    flex-wrap: wrap;
    text-align: left;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 400;
    color:#ffffff;
}
.editIcon,
.editIcon:active,
.editIcon:visited,
.editIcon:link{
    margin-right:1rem;
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    cursor:pointer;
}

.deleteIcon,
.deleteIcon:active,
.deleteIcon:visited,
.deleteIcon:link{
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    cursor: pointer;
}


.editIcon:hover,
.deleteIcon:hover{
    background-color: #7295c4;
}

.iconContainer{
    display:flex;
    flex:row;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
}


@media(max-width:100em){
    .foodListContainer{
        margin: 1rem 20rem;
    }

    .editIcon,
    .editIcon:active,
    .editIcon:visited,
    .editIcon:link{
        height: 2rem;
        width:2rem;
    }

    .deleteIcon,
    .deleteIcon:active,
    .deleteIcon:visited,
    .deleteIcon:link{
        height: 2rem;
        width:2rem;
        
    }

    .foodName{
        font-size: 1.6rem;
        line-height: 1.7rem;
    }
    

}

@media(max-width:80em){
    .foodListContainer{
        margin: 1rem 3rem;
    }

    .editIcon,
    .editIcon:active,
    .editIcon:visited,
    .editIcon:link{
        height: 2rem;
        width:2rem;
    }

    .deleteIcon,
    .deleteIcon:active,
    .deleteIcon:visited,
    .deleteIcon:link{
        height: 2rem;
        width:2rem;
        
    }

    .foodName{
        font-size: 1.6rem;
        line-height: 1.8rem;
    }

    .errorMsg{
        font-size: 1.5rem;
    }
    

}

@media(max-width:30em){
    .foodListContainer{
        margin: 1rem 1rem;
    }

    .editIcon,
    .editIcon:active,
    .editIcon:visited,
    .editIcon:link{
        height: 1rem;
        width:1rem;
    }

    .deleteIcon,
    .deleteIcon:active,
    .deleteIcon:visited,
    .deleteIcon:link{
        height: 1rem;
        width:1rem;
        
    }

    .errorMsg{
        font-size: 1.2rem;
    }

    .foodName{
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    
    .iconNever{
        width:2rem;
        height:2rem;
    }
    .iconInfrequent{
        width:2rem;
        height:2rem;
    }
    
    .iconRarely{
        width:2rem;
        height:2rem;
    }
    .iconOk{
        width:2rem;
        height:2rem;
    }


}