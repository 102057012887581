.container{
    text-align: center;
    margin:4rem 0;
    display:flex;
    flex-direction: column;
    align-items: center;
}



.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
    justify-content: space-between;
}

.title{
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}

.listOptions{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
}

.notSelected{
    padding:1rem;
    font-size: 2rem;
    font-weight: 600;
    color:#ffffff;
    border-width: .5rem;
    border-style: none;
    cursor: pointer;
}

.selected{
    padding:1rem; 
    font-size: 2rem;
    font-weight: 600;
    color:#ffffff;
    border-width: .5rem;
    border-color: #ffffff; 
    border-style: solid;

}


.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}



@media(max-width:100em){
    .container{
        margin:2rem .5rem;
    }
    .title{
        font-size: 2rem;
    }

    .listOptions{
        column-gap:1rem;
    }
    

    .notSelected{
        font-size: 1.5rem;
    }

    .selected{
        font-size:1.5rem;
    }
}

@media(max-width:40em){
    .container{
        margin:1rem .5rem;
    }
    .title{
        font-size: 1.4rem;
    }
    .listOptions{
        gap:.1rem;
        margin-right:1rem;
    }
    .notSelected{
        border-width: .2rem;
        font-size: 1.1rem;
    }

    .selected{
        border-width: .2rem;
        font-size:1.1rem;
    }
}