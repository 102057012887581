.container{
    margin:0 0 2rem 0;
}

.info{
    color:#ffffff;
    font-size: 2rem;

}

.btnContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.btn{
    width:10rem;
    margin:.2rem;
    border-color: #ffffff;
    border-width: .2rem;
    border-style: solid;
    cursor:pointer;
}
.btnText{
   
    color:#ffffff;
    font-size: 1.5rem;
    line-height: 1rem;
}

.btn:hover{
    background-color: #7295c4;
}


@media(max-width:80em){
    .btnText{
     font-size: 1.3rem;
    }
    .btn{
        width:8rem;
    }
    .info{
        font-size: 1.7rem;
    }
 }

 @media(max-width:40em){
    .btnText{
     font-size: 1rem;
    }
    .btn{
        width:6rem;
        border-width: .1rem;
    }
    .info{
        font-size: 1.2rem;
    }
    .container{
        margin:0 .5rem 1rem .5rem;
    }
 }

