.btnContainer{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:4rem;
}
.disabled{
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    font-size: 2rem;
    color: #23518D;
    background-color: #42618a;
}
.cancelBtn{
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    font-size: 2rem;
    color: #23518D;
    background-color: #ffffff;
    cursor:pointer;
}
.cancelBtn:hover{
    background-color: #b3d3fd;
}

.saveBtn{
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    font-size: 2rem;
    color: #23518D;
    background-color: #ffffff;
    cursor:pointer;
}
.saveBtn:hover{
    background-color: #b3d3fd;
}

@media(max-width:100em){
    .btnContainer{
        margin-top: 0;
    }

    .saveBtn{
        font-size: 1.5rem;
    }

    .cancelBtn{
        font-size: 1.5rem;
    }
    .disabled{
        font-size: 1.5rem;
    }
}

@media(max-width:40em){
    .btnContainer{
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .saveBtn{
        font-size: 1.3rem;
    }

    .cancelBtn{
        font-size: 1.3rem;
    }
    .disabled{
        font-size: 1.3rem;
    }
}