.container{
    text-align: center;
    margin:4rem 0;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.rowItem{
    display:flex;

}

.logo{
    height:10rem;
    width:10rem;
}

.header{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    gap:3rem;
}
.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}

.title{
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}

.editContainer{
    width:75%;
    margin:2rem;
    padding:3rem;
    border-color: #ffffff;
    border-width: .2;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.foodContainer{
    margin-bottom:2rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:2rem;
}

.label{
    width:12rem;
    text-align: left;
    font-size:2rem;
    font-weight:400;
    color:#ffffff;
}

.descriptionContainer{
    margin:3rem 4rem;
}
.codeDescription{
    text-align: center;
    font-size:2.2rem;
    font-weight:400;
    color:#ffffff;  
    line-height: 3rem;
}


.sourceContainer{
    margin-top: 3rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:2rem;
}

.inputText{
    padding:1rem;
    font-size:2rem;
    font-weight:400;
    color:#23518D;
    width:90%;
}
   
.icon{
    width:3rem;
    height:3rem;
    text-align: right;
}

.iconContainer{
    margin:2rem 0;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:3rem;
}
.iconSelected{
    width:3rem;
    height:3rem;
    padding:.5rem;
    text-align: right;
    border-style: solid;
    border-color: #ffffff;
    border-width: .1rem;
}


@media(max-width:100em){
    .container{
        margin: 2rem 0 0 0;
    }
    .logo{
        height:6rem;
        width:6rem;
    }
    .title{
        font-size: 2rem;
    }

    .foodContainer{
        margin-bottom: 1.5rem;
    }

    .editContainer{
        padding:2rem;
    }

    .iconContainer{
        margin: 0;
    }

    .sourceContainer{
        margin-top: .5rem;
    }

    .codeDescription{
        font-size: 1.7rem;
        line-height: 1rem;
    }

    .inputText{
        padding:.3rem;
        font-size: 1.5rem;
    }
    .label{
        font-size: 1.5rem;
    }

    .descriptionContainer{
        margin:2rem;
    }
    .icon{
        width:2.5rem;
        height:2.5rem;
    }

    .iconSelected{
        width:2.5rem;
        height:2.5rem;
    }
}

@media(max-width:80em){
    .descriptionContainer{
        margin:1rem;
    }

    .codeDescription{
        font-size: 1.6rem;
        line-height: 1.9rem;
    }

    .inputText{
        padding:.3rem;
        font-size: 1.3rem;
    }
    .foodContainer{
        gap:.1rem;
    }
    .sourceContainer{
        gap:.1rem;
    }
    .errorMsg{
        font-size: 1.5rem;
    }

}

@media(max-width:40em){
    .descriptionContainer{
        margin:1rem;
    }

    .codeDescription{
        font-size: 1.3rem;
        line-height: 1.5rem;
    }

    .inputText{
        padding:.3rem;
        font-size: 1.3rem;
    }
    .foodContainer{
        gap:.1rem;
    }
    .sourceContainer{
        gap:.1rem;
    }
    .label{
        font-size: 1.3rem;
        width:6rem;
    }
    .logo{
        width:4rem;
        height:4rem;
    }
    .title{
        font-size: 1.5rem;
    }
    .header{
        gap:1rem;
    }

    .editContainer{
        margin:1rem;
    }
    .container{
        margin:1rem 0;
    }
    .errorMsg{
        font-size: 1.2rem;
    }
}