
.container{
    text-align: center;
    margin:4rem 0;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.title{
    margin:3rem 0 1rem 0;
    padding:0;
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}


.itemContainer{
    width:70%;
    margin:2rem;
    padding:2rem 3rem;
    border-color: #ffffff;
    border-radius: 2rem;
    border-width: .5rem;
    border-style: solid;
    display: flex;
    flex-direction: column;
}
.line1{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.requestInfo{
    display:flex;
    flex-direction: row;
    column-gap:2rem;
    align-items: center;
    justify-content: flex-start;

}

.line2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 2rem;
    
}

.line3{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 2rem;
    
}

.line4{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.itemText{
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
}

.commentContainer{
    display:flex;
    width:100%;
    flex-direction: row;
    column-gap:2rem;
    align-items: center;
    justify-content: flex-start;
}

.labelText{
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
}

.comment{
    width:100%;
    text-align: left;
    font-size:2rem;
    font-weight: 400;
    color:#111111;
}

.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}


@media(max-width:100em){
    .title{
        font-size:2rem;
    }
    .itemText{
        font-size: 1.8rem;
    }
    .labelText{
        font-size: 1.8rem;
    }
    .comment{
        font-size: 1.8rem;
    }
    .errorMsg{
        font-size: 1.8rem;
    }
    .errorMsg{
        font-size: 1.5rem;
    }
 
}

@media(max-width:40em){

    .itemContainer{
        width:90%;
        margin:.5rem .5rem 1rem .5rem;
        padding:.2rem 1rem 1rem 1rem;
        border-width: .1rem;
    }


    .title{
        font-size:1.5rem;
    }
    .itemText{
        font-size: 1rem;
    }
    .labelText{
        font-size: 1rem;
    }
    .comment{
        font-size: 1rem;
    }
    .errorMsg{
        font-size: 1.2rem;
    }
    .commentContainer{
        column-gap: .3rem;
    }
    .line1{
        column-gap:.3rem;
    }
    .line3{
        column-gap:.5rem;
    }

}