.navbar{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:1rem;
}

.logo{
    height:10rem;
    width:10rem;
}
