.navbar{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:1rem;
}

.labelText{
    font-size: 2rem;
    color: #23518D;
}

.label{
    line-height: 0;
    border-radius: 1rem;
    padding:0 1rem;
    background-color: #ffffff;
}
.label:hover{
    background-color: #b3d3fd;
    cursor: pointer;
}
.logo{
    height:10rem;
    width:10rem;
}
.labelLink,
.labelLink:visited{
    text-decoration: none;
    color: #23518D;
}

@media(max-width:100em){
    .container{
        margin:3rem 0;
    }
    .logo{
        height:6rem;
        width:6rem;
    }
}

@media(max-width:80em){
   .labelText{
    font-size: 2rem;
   }
}

@media(max-width:40em){
    .labelText{
     font-size: 1.5rem;
    }
    .logo{
        height:4rem;
        width:4rem;
    }
 }

 @media(max-width:30em){
    .labelText{
     font-size: 1.2rem;
    }
 }