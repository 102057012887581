.container{
    text-align: center;
    margin:4rem 0;
}

.header{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
}

.logo{
    display: flex;
    height:12rem;
    width:12rem;
}
.title{
    display: flex;
}

.titleText{
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}

.inputContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.inputGrid{
    border-width: .2rem;
    border-style: solid;
    border-color:#ffffff;
    border-radius: 1rem;
    padding:4rem 3rem;
    margin:2rem 1rem;
    display: grid;
    grid-template-columns: 1fr 3fr .35fr;
    row-gap: 2rem;
    column-gap: 1rem;
    grid-template-rows: 3rem 3rem;
}

.oneEmail{
    grid-column:1;
    grid-row:1;
    text-align: right;
    font-size: 2rem;
    color:#ffffff;
    
}
.twoEmail{
    grid-column: 2;
    grid-row:1;
    padding:.5rem;
    font-size: 1.7rem;
    color:#23518D;
}
.onePassword{
    grid-column: 1;
    grid-row:2;
    text-align: right;
    font-size: 2rem;
    color:#ffffff;
}
.twoPassword{
    grid-column: 2;
    grid-row:2;
    padding:.5rem;
    font-size: 1.7rem;
    color:#23518D;
}
.threePassword{
    grid-column: 3;
    grid-row:2; 
    height:3rem;
    width:3rem;
}

.buttonContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.buttonStyleDisabled{
    background-color: #ffffff;
    padding:0 2rem;
    pointer-events: none;
    opacity: .1;
    
}

.buttonStyle{
    background-color: #ffffff;
    padding:0 2rem;
    cursor:pointer;
}
.buttonStyle:hover {
    background-color:#b3d3fd;
}

.buttonText{
    line-height: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color:#23518D;
}

.errorMsg{
    font-size: 2rem;
    font-weight: 600;
    color:#FFA500 ;
}
@media(max-width:40em){

    .header{
        gap:1rem;
    }
    .logo{
        height: 4rem;
        width: 4rem;
    }
    .titleText{
        font-size: 1.5rem;
    }

    .inputGrid{
        border-width: .2rem;
        padding:1rem;
        display: grid;
        grid-template-columns:18rem 3rem;
        row-gap: 0;
        column-gap: 1rem;
        grid-template-rows: 2rem 3rem 4rem 3rem;
    }

    .oneEmail{

        grid-column:1;
        grid-row:1;
        text-align: left;
        font-size: 1.5rem;
        color:#ffffff;
        
    }
    .twoEmail{
        grid-column: 1;
        grid-row:2;
        text-align: left;
        font-size: 1.5rem;
        color:#23518D;
    }
    .onePassword{
        padding-top:2rem;
        grid-column: 1;
        grid-row:3;
        text-align: left;
        font-size: 1.5rem;
        color:#ffffff;
    }
    .twoPassword{
        grid-column: 1;
        grid-row:4;
        padding:.5rem;
        font-size: 1.5rem;
        text-align: left;
        color:#23518D;
    }
    .threePassword{
        grid-column: 2;
        grid-row:4; 
        height:3rem;
        width:3rem;
        text-align: left;
    }

    .buttonText{
        font-size: 1.5rem;
    }


}