.container{
    text-align: center;
    margin:4rem 0;
    display:flex;
    flex-direction: column;
}

.title{
    margin:3rem 0 0 0;
    padding:0;
    font-size: 3rem;
    font-weight: 600;
    color:#ffffff;
}
.pickAnimalContainer{
    margin: 1rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:5rem;
    justify-content: center;
}
.animal{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:.5rem;
    justify-content: center;
}
.btn{
    height:2rem;
    width:2rem;

}
.animalText{
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
}

.searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.searchLabel{
    font-size:2rem;
    font-weight: 400;
    color:#ffffff;
    margin-right: 1rem;
    text-align: left;
}
.searchText{
    font-size:2rem;
    font-weight: 400;
    color:#23518D;
}

.disabledSearchBtn{
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    margin-left: 1rem;
    opacity: .1;
    pointer-events: none;
}

.searchBtn,
.searchBtn:active,
.searchBtn:link,
.searchBtn:visited{
    border-color: #ffffff;
    padding:.5rem;
    border-width: .1rem;
    border-style: solid;
    height: 2.5rem;
    width:2.5rem;
    margin-left: 1rem;
    cursor:pointer;
}
.searchBtn:hover{
    background-color: #7295c4;
}

.addBtn,
.addBtn:active,
.addBtn:link,
.addBtn:visited{
    border-color: #ffffff;
    padding:.3rem;
    border-width: .1rem;
    border-style: solid;
    height: 3rem;
    width:3rem;
    margin-left: 1rem;
    cursor:pointer;
}
.addBtn:hover{
    background-color: #7295c4;
}


@media(max-width:100em){
    .container{
        margin:3rem 0;
    }
    .title{
        font-size: 2.5rem;
    }
    .pickAnimalContainer{
        margin:.2rem 0;
    }
}

@media(max-width:80em){
    
    .title{
        font-size: 2rem;
    }

    .disabledSearchBtn{
        height: 2rem;
        width:2rem;
    }

    .addBtn,
    .addBtn:active,
    .addBtn:link,
    .addBtn:visited{
        height: 2.5rem;
        width:2.5rem;

    }

    .searchBtn,
    .searchBtn:active,
    .searchBtn:link,
    .searchBtn:visited{
        height: 2rem;
        width:2rem;
        
    }

    .searchLabel{
        font-size:1.8rem;
        width:8rem;
    }
    .searchText{
        font-size:1.8rem;

    }


}

@media(max-width:40em){
    
    .title{
        font-size: 1.8rem;
    }

    .animalText{
        font-size: 1.8rem;
    }

    .addBtn,
    .addBtn:active,
    .addBtn:link,
    .addBtn:visited{
        height: 1.7rem;
        width:1.7rem;

    }

    .searchBtn,
    .searchBtn:active,
    .searchBtn:link,
    .searchBtn:visited{
        height: 1.5rem;
        width:1.5rem;
        padding:.4rem;
        
    }
    .disabledSearchBtn{
        height: 1.5rem;
        width:1.5rem;
        padding:.4rem;
    }


    .searchLabel{
        margin-right: .2rem;
        font-size:1.6rem;
        width:6rem;
    }
    .searchText{
        font-size:1.6rem;
        width:15rem;
    }

    .searchContainer{
        margin: 1rem 0;
    }


}


@media(max-width:30em){
    
    .title{
       
        font-size: 1.5rem;
    }

    .addBtn,
    .addBtn:active,
    .addBtn:link,
    .addBtn:visited{
        height: 1.6rem;
        width:1.6rem;

    }

    .animalText{
        font-size: 1.5rem;
    }

    .searchBtn,
    .searchBtn:active,
    .searchBtn:link,
    .searchBtn:visited{
        height: 1.4rem;
        width:1.4rem;
        
    }
    .disabledSearchBtn{
        height: 1.4rem;
        width:1.4rem;
    }


    .searchLabel{
        font-size:1.2rem;
        padding-right: .5rem;
        width:4rem;
    }
    .searchText{
        font-size:1.3rem;
        width:10rem;
    }


}